<template>
    <div v-if="careerDetail.name" class="detail-container">
        <div class="detail-req-res">
            <div style="margin-right: 100px; margin-bottom: 20px">
                <p class="detail-title">Requirements</p>
                <div class="req-text" v-for="(req, index) in reqs" :key="index">
                    <div>
                        <span class="dot"></span>
                    </div>
                    <p style="margin-left:5px">
                        {{ req }}
                    </p>
                </div>
            </div>

            <div>
                <p class="detail-title">Responsibilities</p>
                <div class="req-text" v-for="(resp, index) in resps" :key="index">
                    <div>
                        <span class="dot"></span>
                    </div>
                    <p style="margin-left: 5px;">
                        {{ resp }}
                    </p>
                </div>
            </div>
        </div>
        <div class="detail-footer">
            <div class="detail-button" @click.prevent="goForm(name)">
                <p>Apply Now</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['careerDetail'],
  data: function () {
    return {
        name: '',
        reqs: [],
        resps: []
    }
  },
  watch: {
    careerDetail (newCareerDetail, _) {
        const { name, reqs, resps } = newCareerDetail
        this.name = name
        this.reqs = reqs
        this.resps = resps
    }
  },
  methods: {
    goForm (position) {
        position = position.toLowerCase()
        position = position.split(' ').filter(word => word !== '-');
        position = position.join('-')
        this.$router.push(`/careers/${position}`)
    }
  },
  created () {
    const { name, reqs, resps } = this.careerDetail
    this.name = name
    this.reqs = reqs
    this.resps = resps
  }
}
</script>

<style scope>
@media (max-width:570px) {
    .detail-container {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 24px 24px;
        margin: 0 10px;
    }
}

@media (min-width:570px) and (max-width:1200px) {
    .detail-container {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 24px 24px;
    }
}

@media (min-width:1200px) {
    .detail-container {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 24px 24px;
    }
}

@media (max-width:570px) {
    .detail-req-res {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
    }
}

@media (min-width:570px) and (max-width:1200px) {
    .detail-req-res {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px
    }
}

@media (min-width: 1200px) {
    .detail-req-res {
        display: flex;
        margin-bottom: 60px
    }
}
.detail-footer {
    font-family:'Calibri', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.detail-title {
    font-family:'Calibri', sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 17px;
}
.req-text {
    font-family:'Calibri', sans-serif;
    margin-bottom: 10px;
    font-size: 14px;
    /* font-size: 16px; */
    width: 100%;
    display: flex;
    align-items: center;
}
.dot {
    height: 5px;
    width: 5px;
    margin-right: 5px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
}
.detail-button {
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    color: white;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 8px;

    background: #2F74C2;
    border-radius: 16px;
}
</style>
