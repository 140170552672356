<template>
    <div class="card-career-version-2" :style="boxStyle">
      <div>
        <p class="text-career-title-version-2">{{ name }}</p>
        <p class="text-career-status-version-2">{{ status }}</p>
      </div>
      <div class="text-career-seedetail">
        <p v-show="!this.isOpenned" @click="seeDetail">See detail</p>
        <p v-show="this.isOpenned" @click="seeLess" style="color: #2F74C2;">See less</p>
      </div>
    </div>
</template>

<script>
export default {
  props: ['id', 'name', 'status', 'isOpenned', 'isSomeCardOpenned'],
  computed: {
    boxStyle () {
        if (this.isOpenned) {
            return 'border-radius: 24px 24px 0px 0px'
        }
        return 'border-radius: 24px'
    }
  },
  methods: {
    seeDetail () {
      if (this.isSomeCardOpenned) {
        this.$emit('seeLess')
        this.closeCardNotif(this.id)
      } else {
        this.$emit('seeDetail', this.id)
      }
    },
    seeLess () {
      this.$emit('seeLess')
    },
    closeCardNotif (id) {
      this.$emit('closeCardNotif', id)
    }
  }
}
</script>

<style scope>
@media (max-width: 570px) {
  .card-career-version-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 24px 16px 0px ;
    margin: 0 10px;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  }
}

@media (min-width: 570px) and (max-width:1200px) {
  .card-career-version-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 24px 16px 0px ;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  }
}

@media (min-width:1200px) {
  .card-career-version-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 24px 16px 0px ;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  }
}

.text-career-title-version-2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;

  color: #0054B3;
}

.text-career-status-version-2 {
  font-weight: 400;
  font-size: 16px;

  color: #000000;
}

@media (max-width:570px) {
  .text-career-seedetail {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-bottom: 15px;
    font-size: 12px;
    color: #2F74C2;
    font-weight: 500;
    cursor: pointer;
  }
}

@media (min-width:570px) and (max-width:1200px) {
  .text-career-seedetail {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-bottom: 15px;
    font-size: 12px;
    color: #2F74C2;
    font-weight: 500;
    cursor: pointer;
  }
}

@media (min-width: 1200px) {
  .text-career-seedetail {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 30px;
    padding-bottom: 15px;
    font-size: 12px;
    color: white;
    cursor: pointer;
  }
}

.card-career-version-2:hover .text-career-seedetail p {
  color: #2F74C2;
}
</style>
