<template>
  <div class="career-container">
    <Navbar @lang="getLang" color="white"/>

    <vue-element-loading :active="loading" is-full-screen>
        <img src="@/assets/gif/Motion-Logo-.gif" style="width: 50%; max-width: 250px;">
    </vue-element-loading>

    <div class="career-content" :style="{'min-height': windowHeight + 'px', height: 'calc(100vh - 3rem)'}">
      <!-- <img
        src="@/assets/career/coll-bg.jpg"
        alt="very cool background"
        id="bg-career"
      />-->

      <div class="career-text">
        <img @load="imgLoad" src="@/assets/career/Group 286-min.png" alt="career backdrop" />
        <div class="text-career" v-if="lang === 'id'">
          <h1>KARIR</h1>
          <h4>Ambil Bagianmu Untuk Buat Perubahan Baru</h4>
          <div style="display: none">
            Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO.
            Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO.    Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO
          </div>
        </div>
        <div class="text-career" v-else>
          <h1>Career</h1>
          <h4>Take Your Part to Make New Changes</h4>
          <div style="display: none">
            Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO.
            Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO.    Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO. Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO
          </div>

        </div>
      </div>
    </div>

    <div class="career-form">
      <div class="container-career-form">
        <div class="careerform-title">
          <p>{{lang === 'id' ? 'Lihat lowongan yang cocok dengan minatmu' : 'Pick a job that matches your interests'}}</p>
        </div>

        <!-- Version 2 Laptop Size ====================>>> Start Here -->
        <div v-if="!isMobileDevice" v-for="(careerRow, indexRow) in careerRowList" :key="indexRow">
          <div style="margin-bottom: 20px;">
            <div class="career-container-version-2">
              <div v-for="(career, index) in careerRow" :key="index">
                <career-card
                :id="career.id"
                :name="career.name" 
                :status="career.status" 
                :isOpenned="career.isOpenned"
                :isSomeCardOpenned="isSomeCardOpenned"
                @seeDetail="seeDetail"
                @seeLess="seeLess"
                @closeCardNotif="closeCardNotif"
                />
              </div>
            </div>
            <detail-card 
            v-if="indexRow == rowToBeOpenned" 
            :careerDetail="careerDetail"
            style="margin-top: 20px"/>
          </div>
        </div>

        <!-- Version 2 Mobile Size -->
        <div v-if="isMobileDevice" class="career-container-version-2">
          <div v-for="(career, index) in careerRowListMobile" :key="index">
            <career-card style="margin-bottom: 20px;"
            :id="career.id"
            :name="career.name" 
            :status="career.status" 
            :isOpenned="career.isOpenned"
            :isSomeCardOpenned="isSomeCardOpenned"
            @seeDetail="seeDetail"
            @seeLess="seeLess"
            @closeCardNotif="closeCardNotif"
            />

            <detail-card
            v-if="index == rowToBeOpenned"
            :careerDetail="careerDetail"
            style="margin-top: 20px"/>
          </div>
        </div>
        <!-- Version 2 ====================>>> End Here -->
        
        <!-- Version 1 ====================>>> Start Here -->
        <!-- On -->
        <!-- <div class="career-selection">
          <div class="card-career" @click.prevent="detailCareer('career1')">
            <div style="width: 50%;" class="jobTitle">
              <p style="margin-right: 5%;">1.</p> <p>Back End Developer</p>
            </div>
            <p style="margin-right: 20px">
              <i class="fas fa-angle-down" style="font-size: 1.5rem"></i>
            </p>
          </div>

          <transition name="fade">
            <div class="qual-1 qual-box" v-if="career1 === true">
              <div class="qual-text">
                <div class="work-desc">
                  <h4>Job Description:</h4>
                  <ul>
                    <li>Utilize backend data services.</li>
                    <li>Refactor, optimize and improve the existing codebase for maintenance and scale.</li>
                    <li>Interface with customer and internal teams to gather requirements and develop software solutions.</li>
                    <li>Document the development process, architecture, and standard components.</li>
                    <li>Keep abreast of new trends and best practices in web development.</li>
                  </ul>
                </div>
                <div class="work-desc">
                  <h4>Qualification:</h4>
                  <ul>
                    <li>2 Year min experience.</li>
                    <li>Have experience of integration with ad network, social media, and POS system.</li>
                    <li>Have proficient experience in creating integration development and documentation.</li>
                    <li>Have worked with projects with QR Code technologies.</li>
                    <li>Understanding in network infrastructure and topologies.</li>
                  </ul>
                </div>
                <button class="apply-btn" @click.prevent="goForm('backend-developer')">APPLY</button>
              </div>
            </div>
          </transition>

          <div class="card-career" @click.prevent="detailCareer('career2')">
            <div style="width: 50%;" class="jobTitle">
              <p style="margin-right: 5%;">2.</p> <p>Fullstack Development</p>
            </div>
            <p style="margin-right: 20px">
              <i class="fas fa-angle-down" style="font-size: 1.5rem"></i>
            </p>
          </div>

          <transition name="fade">
            <div class="qual-1 qual-box" v-if="career2 === true">
              <div class="qual-text">
                <div class="work-desc">
                  <h4>Job Description:</h4>
                  <ul>
                    <li>Use appropriate design pattern and object oriented approach.</li>
                    <li>Develop code according to coding conventions, structures and easy to understand.</li>
                    <li>Implement TDD and conduct unit testing, integration testing, debugging.</li>
                    <li>Participate on performance test and support automated testing.</li>
                    <li>Participate on code reviews and peer-programming.</li>
                    <li>Write technical documentation of the code.</li>
                  </ul>
                </div>
                <div class="work-desc">
                  <h4>Qualification:</h4>
                  <ul>
                    <li>Have experience working (minimum 2 years) with various team with any related development approach.</li>
                    <li>Have exposure / experience on (front-end and/or back-end) software construction using any of following technologies.</li>
                    <li>Have experience of SQL statements and basic database entity relationship.</li>
                    <li>Experience in using IDE and other productivity tools effectively, bug reporting, single/multi-threaded code analysis/coverage, etc.</li>
                    <li>Have exposure in using tools for build and/or test automation.</li>
                    <li>Familiar with MVC.</li>
                    <li>Proactive and excellent interpersonal skill, including but not limited to teaching/sharing/learning, brainstorming, presentation, etc.</li>
                    <li>Responsive web design and development.</li>
                    <li>Familiarity on any of following; application server, UI/UX, graphic design, social media and low-level (OS, hardware) issues of application development is a plus.</li>
                  </ul>
                </div>
                <button class="apply-btn" @click.prevent="goForm('fullstack-development')">APPLY</button>
              </div>
            </div>
          </transition> -->

          <!-- Off -->
          <!-- <div class="card-career" @click.prevent="detailCareer('career3')">
            <div style="width: 50%;" class="jobTitle">
              <p style="margin-right: 5%;">3.</p> <p>Business Development</p>
            </div>
            <p style="margin-right: 20px">
              <i class="fas fa-angle-down" style="font-size: 1.5rem"></i>
            </p>
          </div>

          <transition name="fade">
            <div class="qual-1 qual-box" v-if="career3 === true">
              <div class="qual-text">
                <div class="work-desc">
                  <h4>Job Description:</h4>
                  <ul>
                    <li>Strong communication and social skills.</li>
                    <li>Strong on power point.</li>
                    <li>Abilty to analysis market merchant.</li>
                    <li>Creative talents and the ability to solve tough problems.</li>
                    <li>In-depth knowledge of the digital industry and its current events.</li>
                    <li>Ability to handle pressure and meet deadlines.</li>
                  </ul>
                </div>
                <div class="work-desc">
                  <h4>Responsibility:</h4>
                  <ul>
                    <li>Identifying new leads potential for merchant.</li>
                    <li>Recruiting merchants for acquisition.</li>
                    <li>Pitching products and services.</li>
                    <li>Maintaining relations with existing merchants.</li>
                    <li>Responsible for all needs merchants.</li>
                    <li>Working together with IT and Networking Team in terms of installation and maintenance.</li>
                  </ul>
                </div>
                <button class="apply-btn" @click.prevent="goForm('business-development')">APPLY</button>
              </div>
            </div>
          </transition>

          <div class="card-career" @click.prevent="detailCareer('career4')">
            <div style="width: 50%;" class="jobTitle">
              <p style="margin-right: 5%;">4.</p> <p>Account Executive</p>
            </div>
            <p style="margin-right: 20px">
              <i class="fas fa-angle-down" style="font-size: 1.5rem"></i>
            </p>
          </div>

          <transition name="fade">
            <div class="qual-1 qual-box" v-if="career4 === true">
              <div class="qual-text">
                <div class="work-desc">
                  <h4>Job Description:</h4>
                  <ul>
                    <li>Male or Female: 22 - 27 years old.</li>
                    <li>Bachelor's degree in Management, Marketing Communication, Public Relation or other related majors.</li>
                    <li>Have a good knowledge of marketing, sales, advertising, digital.</li>
                    <li>Good communication & presentation skill.</li>
                    <li>Familiar and have passion in selling products and getting new clients.</li>
                    <li>Initiative & creative.</li>
                    <li>Have a wide and good networking.</li>
                    <li>Able to handle multiple project at the same time.</li>
                    <li>Able to work in a team environment and/or independently.</li>
                    <li>Highly passionate in Digital Marketing.</li>
                    <li>Excellent interpersonal skills, communication skills and presentation skills.</li>
                    <li>Having an internship/working experience in Digital marketing or creative agency.</li>
                    <li>Strong understanding of Digital Advertising.</li>
                  </ul>
                </div>
                <div class="work-desc">
                  <h4>Responsibility:</h4>
                  <ul>
                    <li>Identifying new leads potential for clients.</li>
                    <li>Provide clients with estimate cost of advertising.</li>
                    <li>Deliver advertising or illustration proofs to clients for approval.</li>
                    <li>Pitching products and services.</li>
                    <li>Maintaining relations with existing clients.</li>
                    <li>Responsible for all needs clients.</li>
                    <li>Working together with IT and Networking Team in terms of installation and implementation.</li>
                  </ul>
                </div>
                <button class="apply-btn" @click.prevent="goForm('account-executive')">APPLY</button>
              </div>
            </div>
          </transition> -->

          <!-- On -->
          <!-- <div class="card-career" @click.prevent="detailCareer('career5')">
            <div style="width: 50%;" class="jobTitle">
              <p style="margin-right: 5%;">3.</p> <p>Data Scientist</p>
            </div>
            <p style="margin-right: 20px">
              <i class="fas fa-angle-down" style="font-size: 1.5rem"></i>
            </p>
          </div>

          <transition name="fade">
            <div class="qual-1 qual-box" v-if="career5 === true">
              <div class="qual-text">
                <div class="work-desc">
                  <h4>Job Description:</h4>
                  <ul>
                    <li>Responsible for developing Operational Models.</li>
                    <li>Carry out data analytics and optimization using machine learning & deep learning.</li>
                    <li>Involved in strategic planning for data analytics.</li>
                    <li>Integrate data & perform ad-hoc analysis.</li>
                    <li>Fill in the gap between the stakeholders and customer.</li>
                  </ul>
                </div>
                <div class="work-desc">
                  <h4>Qualification:</h4>
                  <ul>
                    <li>Statistical & Analytical skills.</li>
                    <li>Data Mining.</li>
                    <li>Machine Learning & Deep learning principles.</li>
                    <li>In-depth programming knowledge (SAS/R/ Python coding).</li>
                    <li>Hadoop-based analytics.</li>
                    <li>Data optimization.</li>
                    <li>Decision making and soft skills.</li>
                  </ul>
                </div>
                <button class="apply-btn" @click.prevent="goForm('data-scientist')">APPLY</button>
              </div>
            </div>
          </transition>

          <div class="card-career" @click.prevent="detailCareer('career6')">
            <div style="width: 50%;" class="jobTitle">
              <p style="margin-right: 5%;">4.</p> <p>NOC & Research</p>
            </div>
            <p style="margin-right: 20px">
              <i class="fas fa-angle-down" style="font-size: 1.5rem"></i>
            </p>
          </div>

          <transition name="fade">
            <div class="qual-1 qual-box" v-if="career6 === true">
              <div class="qual-text">
                <div class="work-desc">
                  <h4>Job Description:</h4>
                  <ul>
                    <li>Help and Solve Client Problem.</li>
                    <li>Maintain Networking System.</li>
                    <li>Find and Solve Networking Problem.</li>
                    <li>Research about new feature to help company growth.</li>
                  </ul>
                </div>
                <div class="work-desc">
                  <h4>Qualification:</h4>
                  <ul>
                    <li>Experience in various network devices (Ex: Mikrotik, Cisco, Fortigate).</li>
                    <li>Deep knowledge in networking system.</li>
                    <li>Experience in networking tools, OS.</li>
                    <li>Have good understanding in Website (Ex: Cookie, HTTPS, Cache, Browsing History).</li>
                    <li>High analytical and problem solving.</li>
                    <li>Fast Learner.</li>
                    <li>Has ability to communicate well.</li>
                    <li>Willing to work in an office.</li>
                  </ul>
                </div>
                <button class="apply-btn" @click.prevent="goForm('noc-research')">APPLY</button>
              </div>
            </div>
          </transition> -->

          <!-- Off -->
          <!-- <div class="card-career" @click.prevent="detailCareer('career7')">
            <div style="width: 50%;" class="jobTitle">
              <p style="margin-right: 5%;">7.</p> <p>Digital Media Planner</p>
            </div>
            <p style="margin-right: 20px">
              <i class="fas fa-angle-down" style="font-size: 1.5rem"></i>
            </p>
          </div>

          <transition name="fade">
            <div class="qual-1 qual-box" v-if="career7 === true">
              <div class="qual-text">
                <div class="work-desc">
                  <h4>Job Description:</h4>
                  <ul>
                    <li>Bachelor's degree; degree or concentration in advertising, marketing, business administration, and communications preferred with 1-2 years in Media Planning role in digital marketing.</li>
                    <li>Max. 30 years old.</li>
                    <li>Fluent in English will be plus.</li>
                    <li>Knowledge in research and data analysis to inform business decisions.</li>
                    <li>Knowledge of market research practices and techniques.</li>
                    <li>A proven track record in developing strategic plans that benefit the client.</li>
                    <li>Good knowledge of the media and how strategy planning influences it.</li>
                    <li>Excellent written and verbal communication skills.</li>
                    <li>The drive to be successful and perform well in all aspects of your strategic work.</li>
                    <li>The flexibility to work over a number of projects and balance your workload.</li>
                    <li>Able to work successfully within a team, handling high volume workload, and meeting tight deadlines under pressure.</li>
                    <li>Proactive, strong analytical thiking, problem solver & creative thinker.</li>
                    <li>Willing to learn new things.</li>
                  </ul>
                </div>
                <div class="work-desc">
                  <h4>Responsibility:</h4>
                  <ul>
                    <li>Developing strategic plans and make some Pitch Deck presentation.</li>
                    <li>Produce weekly and final report after campaign ends.</li>
                    <li>Produce weekly and final report after campaign ends.</li>
                  </ul>
                </div>
                <button class="apply-btn" @click.prevent="goForm('digital-media-planner')">APPLY</button>
              </div>
            </div>
          </transition> -->

        <!-- On -->
        <!-- </div> -->
        <!-- Version 1 ====================>>> End Here -->
        
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import CareerCard from '@/components/Career/CareerCard.vue'
import DetailCard from '@/components/Career/DetailCard.vue'
import axios from 'axios'

export default {
  components: { Navbar, Footer, CareerCard, DetailCard },
  computed: {
    isMobileDevice () {
      if (this.windowWidth >= 0 && this.windowWidth < 1200) {
        return true
      }
      return false
    },
    rowToBeOpenned () {
      /*
      PC
      [
        [{..., isOpenned: true}, {..., isOpenned: true}, {..., isOpenned: true}], 
        [{..., isOpenned: false}, ...], 
        [{..., isOpenned: false}, ...]
      ]
      Mobile [{..., isOpenned: true}, {...}, {...}]
      */
      // Mobile and Ipad version
      let row = -1
      if (this.windowWidth >= 0 && this.windowWidth < 1200) {
        const careerRowListMobile = this.careerRowListMobile
        for (let career of careerRowListMobile) {
          row ++
          if (career["isOpenned"]) {
            return row
          }
        }
      } else {
        // Dektop version
        const careerRowList = this.careerRowList
        for (let careerRow of careerRowList) {
          row ++
          for (let career of careerRow) {
            if (career["isOpenned"]) {
              return row
            }
          }
        }
      }
      return -1
    }
  },
  data: function () {
    return {
      isSomeCardOpenned: false,
      careerDetail: {},
      careerList: [],
      careerRowList: [[]],
      careerRowListMobile: [],
      // career1: false,
      // career2: false,
      // career3: false,
      // career4: false,
      // career5: false,
      // career6: false,
      // career7: false,
      windowWidth: 0,
      windowHeight: 0,
      imgCount: 1,
      loading: true,
      lang: ''
    }
  },
  metaInfo: {
    title: 'HIGO | Lowongan kerja',
    meta: [
      {
        name: 'description',
        content:
          'Dapatkan lowongan kerja Jakarta di dunia startup dan menjadi keluarga baru HIGO.'
      },
      {
        name: 'keywords',
        content:
          'Lowongan kerja, Lowongan kerja di jakarta, Lowongan kerja startup, Lowongan kerja HIGO'
      }
    ]
  },
  created () {
    window.scrollTo(0, 0)
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    this.getCareerRowList()
  },
  methods: {
    getLang (value) {
      this.lang = value
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    },
    imgLoad () {
      this.imgCount--
      if (!this.imgCount) {
        this.loading = false
      }
    },
    goForm (str) {
      this.$router.push(`/careers/${str}`)
    },
    async getCareerRowList() {
      try {
        
        //========= Get data from DB Version =========//
        // Get API from DB
        const res = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_API_BASE_URL}/web/careers`
        })

        const careerRowList = res.data

        //========= Get data from DB Version =========//

        // const careerRowList = [
        // [{
        //   id: 'career-1',
        //   name: 'UI UX Designer',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Minimum 1 year experience in UI/UX Design',
        //       'Proficient in Adobe Creative Suite or other design apps',
        //       'Strong communication, conceptual thinking, and produce illustrative material',
        //       'Experienced of interface design, creative work, and production work',
        //       'Ability to manage multiple tasks in a fast-paced environment',
        //       'Pixel perfect',
        //       'Good critical thinking',
        //       'Portofolio of work  '
        //     ],
        //     resps: [
        //       'Web & Mobile Design and layout',
        //       'Illustrate design ideas using storyboards, process flows and sitemaps',
        //       'Develop UI mockups and prototypes that clearly illustrate',
        //       'Identify and troubleshoot UX problems (e.g. responsiveness)',
        //       'Create design accross multiples devices standard (Dekstop, Android, iOS)'
        //     ]
        //   }
        // },
        // {
        //   id: 'career-2',
        //   name: 'Legal',
        //   status: 'Intern',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Final Year student or Graduate from Diploma / Bachelor Degree',
        //       'Majoring in Law or related field',
        //       'Advanced with Microsoft Office products including Word, Excel, and PowerPoint',
        //       'Detail oriented',
        //       'Can work independently as well as a team member',
        //       'Willing to work from Monday - Friday, 8.30AM - 5.30PM',
        //       'Placement at Kebon Jeruk - Jakarta Barat',
        //       'Willing to use a personal laptop',
        //       'Have experience on Legal Internship is plus'
        //     ],
        //     resps: [
        //       'Help draft and edit the contract templates',
        //       'Drafted various court documents, invoices, and enclosures at attorneys’ requests',
        //       'Responsible for providing legal support',
        //       'Assisted in putting together a standard form of a confidentiality agreement',
        //       'Proofed documents and submitted them to attorneys for review',
        //       'Working with confidential documents and under confidentiality obligations',
        //       'Contract formation, preparation, negotiation, review, and drafting',
        //       'Identifies key risk areas in a contract'
        //     ]
        //   }
        // },
        // {
        //   id: 'career-3',
        //   name: 'Graphic Designer',
        //   status: 'Intern',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Final Year student or Graduate from Diploma / Bachelor Degree',
        //       'Majoring in DKV / Graphic Design / Animation or relevant studies',
        //       'Advanced with  Photoshop, Illustrator or other software design',
        //       'Advanced with Video Editor Software is a plus',
        //       'Detail oriented',
        //       'Can work independently as well as a team member',
        //       'Willing to work from Monday - Friday, 8.30AM - 5.30PM',
        //       'Placement at Kebon Jeruk - Jakarta Barat',
        //       'Willing to use a personal laptop',
        //     ],
        //     resps: [
        //       'Fulfill design request for operational',
        //       'Make motion graphic'
        //     ]
        //   }
        // }],
        // [{
        //   id: 'career-4',
        //   name: 'Industrial Engineer',
        //   status: 'Intern',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Final Year student or Graduate from Diploma / Bachelor Degree',
        //       'Majoring in Industrial Engineering or relevant studies',
        //       'Knowledge in ISO Certification',
        //       'Detail oriented',
        //       'Can work independently as well as a team member',
        //       'Willing to work from Monday - Friday, 8.30AM - 5.30PM',
        //       'Placement at Kebon Jeruk - Jakarta Barat',
        //       'Willing to use a personal laptop',
        //     ],
        //     resps: [
        //       'Create Flow and Documentation for ISO Certification'
        //     ]
        //   }
        // },
        // {
        //   id: 'career-5',
        //   name: 'Merchant Marketing Specialist',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Bachelor\'s degree in marketing, communications, or related field',
        //       'Max. 30 years old',
        //       'Required languege(s) : Fluent in English will be plus',
        //       'Experience in planning and leading initiatives',
        //       'Knowledge of market research practices and techniques',
        //       'Knowledge in surveys and data analytics',
        //       'Experience with integrated marketing campaigns',
        //       'Good communication and presentation skills',
        //       'Willing to learn new thing'
        //     ],
        //     resps: [
        //       'Handling existing F&B, retail and corporate customers',
        //       'Marketing consultant for existing customers',
        //       'Improve the performance of product usage'
        //     ]
        //   }
        // },
        // {
        //   id: 'career-6',
        //   name: 'Junior Strategist',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'At least 1-2 years experience in digital scope, or project management, if you have a good portfolio is a plus point (experienced in internship/bootcamp is open)',
        //       'Experience in end to end campaign creation',
        //       'Have a solid experience in delivering digital marketing through process through presenting digital rationale, also creating online to offline activation',
        //       'Great communication skills with a high collaborative spirit and problem solving mindset',
        //       'Preferably have a creative agency background',
        //       'Previous exposure in digital marketing strategy is a plus',
        //       'Have an excellent communication and presentation skills'
        //     ],
        //     resps: [
        //       'Developing campaign, brand and digital/offline activations for marketing program',
        //       'Researching content and consumer trends to ensure that content is relevant and appealing',
        //       'Developing and Project planning, then ensuring the content remains consistent across at platforms',
        //       'Responsible to built communication strategy, creating content ideas, and providing input for internal & external parties. social media and microsite within strategic and creative team'
        //     ]
        //   }
        // }],
        // [{
        //   id: 'career-7',
        //   name: 'Business Development - ISP Partnership',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Strong communication and social skills',
        //       'Strong on power point',
        //       'Good looking',
        //       'In-depth knowledge of the digital industry and its current events',
        //       'Creative talents and the ability to solve tough problems',
        //       'Ability to handle pressure and meet deadlines',
        //       'Experiences in sales or business development (prefer ISP or Provider)'
        //     ],
        //     resps: [
        //       'Identifying new leads potential for ISP',
        //       'Collaborate with various ISP',
        //       'Pitching products and services',
        //       'Maintaining relations with existing ISP',
        //       'Working together with IT and Networking Team in terms of installation and maintenance'
        //     ]
        //   }
        // },
        // {
        //   id: 'career-8',
        //   name: 'Business Development - SME',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Have private vehicle, driving licence & laptop',
        //       'Creative and problem solving',
        //       'Ability to analysis market merchant',
        //       'Strong communication and social skills',
        //       'Good looking',
        //       'Strong on power point and excel',
        //       'In-depth knowledge of the digital industry and its current events',
        //       'Creative talents and the ability to solve tough problems',
        //       'Ability to relocate or business traveling to other cities in Indonesia',
        //       'Ability to handle pressure and meet deadlines'
        //     ],
        //     resps: [
        //       'Identifying new leads potential for location/merchant',
        //       'Analyze merchant trends',
        //       'Pitching products and services',
        //       'Recruiting merchants for acquisition',
        //       'Working together with IT and Networking Team in terms of installation and maintenance'
        //     ]
        //   }
        // },
        // {
        //   id: 'career-9',
        //   name: 'Product Manager',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Bachelor\'s Degree from Computer Science, Software Engineer, Information Technology and any other related field',
        //       'Preferably with 3 years of working as Product Manager or Project Management',
        //       'Strong analytical and good in execution',
        //       'Self starter with strong strategic planning and organizational skills',
        //       'Good collaboration and leading team',
        //       'Excellent communicator of product decisions',
        //       'Able to work in a team and independently',
        //       'SEO knowledge',
        //       'Create Flow / Documentation for ISO',
        //     ],
        //     resps: [
        //       'Create, manage, track, and execute project plans & product development in a timely manner',
        //       'Track project progress and document project actions, risks, issues, and decisions',
        //       'Defining product strategy, vision, and roadmap',
        //       'Responsible for managing the entire project lifecycle of technology projects, including design, implementation, testing, and release',
        //       'Collaborate with other departments to do requirements and making sure that product is align with the roadmap',
        //       'Distribute updated project plans, meeting minutes, and project status reports',
        //       'Gather market insight and analyze to create a product strategy',
        //       'Propose and launch new product ideas',
        //     ]
        //   }
        // }],
        // [{
        //   id: 'career-10',
        //   name: 'Software Developer',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Have experience working (minimum 2 years) with various team with any related development approach.',
        //       'Have exposure / experience on (front-end and/or back-end) software construction using any of following technologies.',
        //       'Have experience of SQL statements and basic database entity relationship.',
        //       'Experience in using IDE and other productivity tools effectively, bug reporting, single/multi-threaded code analysis/coverage, etc.',
        //       'Have exposure in using tools for build and/or test automation.',
        //       'Familiar with MVC.',
        //       'Proactive and excellent interpersonal skill, including but not limited to teaching/sharing/learning, brainstorming, presentation, etc.',
        //       'Responsive web design and development.',
        //       'Familiarity on any of following; application server, UI/UX, graphic design, social media and low-level (OS, hardware) issues of application development is a plus.'
        //     ],
        //     resps: [
        //       'Use appropriate design pattern and object oriented approach.',
        //       'Develop code according to coding conventions, structures and easy to understand.',
        //       'Implement TDD and conduct unit testing, integration testing, debugging.',
        //       'Participate on performance test and support automated testing.',
        //       'Participate on code reviews and peer-programming.',
        //       'Write technical documentation of the code.'
        //     ]
        //   }
        // },
        // {
        //   id: 'career-11',
        //   name: 'Quality Assurance',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Bachelor\'s Degree in Computer Science or related fields',
        //       '1 year experience as Quality Assurance/Software Tester',
        //       'Experience in software testing (alpha and beta testing)',
        //       'Experience in Automatic Testing, Whitebox & Blackbox, Software Tester, SDLC, SQL, RDBMS, Programming',
        //       'Have knowledge in ETL and Query SQL',
        //       'Have experience develop test strategy, test plans, test design, test cases and test scenario',
        //       'Familiar with programming language (Python, PHP, Vue Js, React Js, Android Studio, Flutter)',
        //       'Hands - on experience with CI/CD tools',
        //     ],
        //     resps: [
        //       'Create tests to identify software problems',
        //       'Analyze bugs and errors found during tests',
        //       'Document results of tests for the software development team',
        //       'Recommend improvements in software to enhance user experience',
        //       'Motivate the development process for efficiency and performance',
        //       'Works together with the software developer to enhance and improve product',
        //       'Research and compare similar competitor products',
        //       'Maintain updated knowledge of industry trends and advancements ',
        //     ]
        //   }
        // },
        // {
        //   id: 'career-12',
        //   name: 'Data Scientist',
        //   status: 'Full-Time',
        //   isOpenned: false,
        //   detail: {
        //     reqs: [
        //       'Statistical & Analytical skills',
        //       'Data Mining',
        //       'Machine Learning & Deep learning principles',
        //       'In-depth programming knowledge (SAS/R/ Python coding)',
        //       'Hadoop-based analytics',
        //       'Data optimization',
        //       'Decision making and soft skills',
        //     ],
        //     resps: [
        //       'Responsible for developing Operational Models Data',
        //       'Carry out data analytics and optimization using machine learning & deep learning',
        //       'Involved in strategic planning for data analytics',
        //       'Integrate data & perform ad-hoc analysis',
        //       'Fill in the gap between the stakeholders and customer',
        //     ]
        //   }
        // }]
        // ]
  
        this.careerRowList = careerRowList
        this.getCareerRowListMobile(careerRowList)
        
      } catch (error) {
        console.log(error)
        console.log("Something Wrong Happenned")
      }
    },
    getCareerRowListMobile (careerRowList) {
      let careerRowListMobile = []
      for (let careerMobile of careerRowList) {
        careerRowListMobile = [...careerRowListMobile, ...careerMobile]
      }
      this.careerRowListMobile = careerRowListMobile
    },
    seeDetail (id) {
      this.isSomeCardOpenned = true
      if (this.windowWidth >= 0 && this.windowWidth < 1200) {
        // Mobile version
        const windowWidth = window.screen.width
        if (windowWidth >= 580 && windowWidth <= 700) {
          this.careerDetail = {}
        }

        let newCareerListMobile = []
        for (let career of this.careerRowListMobile) {
          if (career["id"] == id) {
            const newCareer = {...career, "isOpenned": true}
            this.careerDetail = {...newCareer.detail, "name": newCareer.name}
            newCareerListMobile.push(newCareer)
            continue
          }
          newCareerListMobile.push(career)
        }

        this.careerRowListMobile = newCareerListMobile
      } else {
        let newCareerRowList = []
        const careerRowList = this.careerRowList
        for (let careerRow of careerRowList) {
          const newCareerRow = []
          for (let career of careerRow) {
            if (career["id"] == id) {
              const newCareer = {...career, "isOpenned": true}
              this.careerDetail = {...newCareer.detail, "name": newCareer.name}
              newCareerRow.push(newCareer)
              continue
            }
            newCareerRow.push(career)
          }
          newCareerRowList.push(newCareerRow)
        }
        this.careerRowList = newCareerRowList
      }
    },
    seeLess () {
      if (this.windowWidth >= 0 && this.windowWidth < 1200) {
        // Mobile Version
        let newCareerRowList = []
        const careerRowListMobile = this.careerRowListMobile
        for (let career of careerRowListMobile) {
          if (career["isOpenned"]) {
            const newCareer = {...career, "isOpenned": false}
            newCareerRowList.push(newCareer)
            continue
          } else {
            if (this.isSomeCardOpenned) {
              const newCareer = {...career, "isOpenned": false}
              newCareerRowList.push(newCareer)
              continue
            }
          }
          newCareerRowList.push(career)
        }
        this.careerRowListMobile = newCareerRowList
        this.careerDetail = {}
      } else {
        let newCareerRowList = []
        const careerRowList = this.careerRowList
        for (let careerRow of careerRowList) {
          const newCareerRow = []
          for (let career of careerRow) {
            if (career["isOpenned"]) {
              const newCareer = {...career, "isOpenned": false}
              newCareerRow.push(newCareer)
              continue
            } else {
              if (this.isSomeCardOpenned) {
                const newCareer = {...career, "isOpenned": false}
                newCareerRow.push(newCareer)
                continue
              }
            }
            newCareerRow.push(career)
          }
          newCareerRowList.push(newCareerRow)
        }
        this.careerRowList = newCareerRowList
      }
    },
    closeCardNotif (id) {
      this.isSomeCardOpenned = false
      this.seeDetail(id)
    },
    detailCareer (pos) {
      if (pos === 'career1') {
        this.career1 = !this.career1
        this.career2 = false
        this.career3 = false
        this.career4 = false
        this.career5 = false
        this.career6 = false
        this.career7 = false
      }

      if (pos === 'career2') {
        this.career2 = !this.career2
        this.career1 = false
        this.career3 = false
        this.career4 = false
        this.career5 = false
        this.career6 = false
        this.career7 = false
      }

      if (pos === 'career3') {
        this.career3 = !this.career3
        this.career1 = false
        this.career2 = false
        this.career4 = false
        this.career5 = false
        this.career6 = false
        this.career7 = false
      }

      if (pos === 'career4') {
        this.career4 = !this.career4
        this.career1 = false
        this.career2 = false
        this.career3 = false
        this.career5 = false
        this.career6 = false
        this.career7 = false
      }

      if (pos === 'career5') {
        this.career5 = !this.career5
        this.career1 = false
        this.career2 = false
        this.career4 = false
        this.career3 = false
        this.career6 = false
        this.career7 = false
      }

      if (pos === 'career6') {
        this.career6 = !this.career6
        this.career1 = false
        this.career2 = false
        this.career4 = false
        this.career5 = false
        this.career3 = false
        this.career7 = false
      }

      if (pos === 'career7') {
        this.career7 = !this.career7
        this.career1 = false
        this.career2 = false
        this.career4 = false
        this.career5 = false
        this.career6 = false
        this.career3 = false
      }
    }
  },
  metaInfo: {
    title: 'Career | HIGO',
    meta: [
      {
        name: 'description',
        content: 'Free WiFi Platform di Indonesia dengan ratusan spot internet gratis di lokasi HIGOspot, mulai dari ruang publik, hotel, mall, kafe, dan resto'
      },
      {
        name: 'keywords',
        content: 'Free WiFi Platform di Indonesia, WiFi Advertising Platform Indonesia, Ratusan Spot Internet Gratis, Internet Gratis di Ruang Publik & Cafe'
      }
    ]
  }
}
</script>

<style>

@media (max-width:570px) {
  .career-container-version-2 {
    font-family:'Calibri', sans-serif;
    display: grid;
    grid-template-columns: 100%;
    gap: 24px;
  }
}

@media (min-width:570px) and (max-width:1200px) {
  .career-container-version-2 {
    font-family:'Calibri', sans-serif;
    display: grid;
    grid-template-columns: 100%;
    gap: 24px;
  }
}

@media (min-width: 1200px) {
  .career-container-version-2 {
    font-family:'Calibri', sans-serif;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 24px;
  }
}

/* transition animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/*  */
.career-content {
  padding-top: 50px;
  height: 100vh;
  position: relative;

  background: url("~@/assets/gif/unsplash.jpg") no-repeat center center;
  background-size: cover;
  overflow: hidden;
}

.career-text {
  width: 90%;
  height: 250px;
  position: absolute;
  bottom: 0;
}

.career-text img {
  width: 100%;
  height: 100%;
  z-index: -20;
}

.text-career {
  color: white;
  position: absolute;
  bottom: 10vh;
  width: 20%;
  left: 6vw;
}

.text-career h1 {
  font-size: 3.5rem;
  font-weight: 800;
}

.text-career h4 {
  font-size: 1.1rem;
}

.careerform-title {
  font-family:'Calibri', sans-serif;
  width: 60%;
  font-size: 18px;
  font-weight: 700;
  padding: 5% 5% 5% 0%;
}

.careerform-title h4 {
  font-size: 1.2rem;
}

.career-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-career {
  height: 3.8rem;
  width: 80%;
  border-radius: 50px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  cursor: pointer;

  transition: 0.3s;

  -webkit-box-shadow: 1px 8px 20px 5px rgba(209, 209, 209, 0.71);
  -moz-box-shadow: 1px 8px 20px 5px rgba(209, 209, 209, 0.71);
  box-shadow: 1px 8px 20px 5px rgba(209, 209, 209, 0.71);
}

.career-form {
  width: 100%;
  /* min-height: 100vh; */
  margin-bottom: 5%;
}

.container-career-form {
  margin: 0 auto;
  /* min-height: 100vh; */
}

.card-career p {
  font-family: "Arial", sans-serif;
}

.qual-box {
  background-color: #f5f5f5;
  width: 80%;
  padding: 30px;
  border-radius: 30px;

  /* -webkit-box-shadow: 1px 8px 20px 5px rgba(209, 209, 209, 0.71);
  -moz-box-shadow: 1px 8px 20px 5px rgba(209, 209, 209, 0.71);
  box-shadow: 1px 8px 20px 5px rgba(209, 209, 209, 0.71); */
}

.qual-text h4 {
  margin-bottom: 10px;
}

ul {
  margin-left: 5% !important;
  list-style: none;
  font-family: "Arial", sans-serif;
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #0245ab; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-size: 1.5em;
}
.jobTitle {
   width: 100% !important;
   display: flex;
   align-items: center;
 }

.apply-btn {
  margin-top: 20px;
  border: none;
  font-family: 'Cocogoose', sans-serif;
  font-size: 16px;
  border-radius: 50px;
  width: 85px;
  height: 30px;
  color: white;
  background-color: #0245ab;

  transition: 0.3s
}

.work-desc {
  margin-bottom: 1.5rem;
}

.apply-btn:hover {
  color: #0245ab;
  background-color: #f9ca45;
  cursor: pointer;
}

@media screen and (min-width: 1400px) {
  .career-text {
    height: 300px;
  }
}

ul {
  margin-left: 12%;
}

@media screen and (min-width: 1200px) {
  .container-career-form {
    width: 1140px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container-career-form {
    width: 960px;
  }
  .careerform-title {
    width: 100%;
    padding: 12% 5% 6% 0%;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container-career-form {
    width: 720px;
  }
  .careerform-title {
    width: 100%;
    padding: 12% 5% 6% 0%;
    text-align: left;
  }
}

@media screen and (min-width: 540px) and (max-width: 767px) {
  .container-career-form {
    width: 540px;
  }
  .careerform-title {
    text-align: left;
    width: 100%;
    padding: 12% 5% 6% 0%;
    text-align: left;
  }
}

@media screen and (max-width: 576px) {
  .career-text {
    width: 100%;
    height: 318px;
    position: absolute;
    bottom: 0;
 }

 .career-content {
   height: calc(100vh - 50vh) !important;
   min-height: calc(100vh - 50vh) !important;
 }

 .career-text img {
  width: 115%;
  bottom: 0;
  height: unset;
  position: absolute;
  z-index: unset;
 }

 .text-career {
   color: white;
  position: absolute;
  bottom: 5%;
  width: 40%;
  left: 5%;
 }

 @media (max-width: 570px) {
  .careerform-title {
   width: 100%;
   padding: 12% 5% 6% 0%;
   text-align: left;
   margin-left: 10px;
 }
}

@media (min-width: 570px) and (max-width:1200px) {
  .careerform-title {
   width: 100%;
   padding: 12% 5% 6% 0%;
   text-align: left;
 }
}

@media (min-width:1200px) {
  .careerform-title {
   width: 100%;
   padding: 12% 5% 6% 0%;
   text-align: left;
 }
}

 .career-content {
   height: 65vh;
 }

 .card-career {
   width: 90%;
   padding: 0 5%;
 }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .text-career {
      color: white;
      position: absolute;
      bottom: 9vh;
      width: 28%;
      left: 3vw;
  }

  .text-career  h1 {
    font-size: 2.5rem;
  }

  .text-career  h4 {
    font-size: .9rem;
  }
}

@media (max-width: 575.98px) {
  .text-career {
   color: white;
  position: absolute;
  bottom: 5%;
  width: 40%;
  left: 5%;
 }

  .text-career  h1 {
    font-size: 2.2rem;
    margin-bottom: 5px;
  }

  .text-career  h4 {
    font-size: .9rem;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .text-career {
    bottom: 2%;
    width: 51%;
    left: 3%;
  }
}
</style>
